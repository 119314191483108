import React from "react";
export default function Karte() {
  return (
    <div className="supplies-container">
      <iframe
        width="100%"
        height="576"
        src="https://app.developer.here.com/coronavirus/"
        frameborder="0"
      ></iframe>
    </div>
  );
}
